import { createSlice } from '@reduxjs/toolkit'
import { STATUSES } from '@constants/slices'
import { clearCurrentUser } from '@services/currentUser'
import api from '@services/api'

const slice = createSlice({
    name: 'validateToken',
    initialState: {
        validateToken: {
            status: STATUSES.INIT,
        },
        value: false,
        errorStatus: null,
    },
    reducers: {
        loading: (state, { payload: { method } }) => {
            return {...state, errorStatus: null, [method]: { status: STATUSES.LOADING } }
        },

        loaded: (state, { payload: { method, data } }) => {
            return {
                ...state,
                errorStatus: null,
                value: true,
                [method]: { status: STATUSES.LOADED },
            }
        },

        error: (state, { payload: { method, errorStatus } }) => {
            return {...state, errorStatus, [method]: { status: STATUSES.ERROR } }
        },
    },
})

export const validateToken = () => dispatch => {
    dispatch(loading({ method: 'validateToken' }))
    return api({
            method: 'POST',
            areaPath: '/memberarea/token/',
            endpoint: 'validate',
        })
        .then(res => {
            if (res.status === 200) {
                //TODO get user info from api, but maybe not
                //setCurrentUser({ name, nickname, email })

                dispatch(loaded({ method: 'validateToken', data: res.data }))

                return Promise.resolve()
            } else {
                return Promise.reject()
            }
        })
        .catch(e => {
            dispatch(error({ method: 'validateToken', errorStatus: e.response.status }))
            return Promise.reject()
        })
}

export const logout = () => dispatch => {
    return api({
            method: 'POST',
            areaPath: '/memberarea/',
            endpoint: 'logout',
        })
        .then(res => {
            window.location.href = process.env.REACT_APP_TEST_URL

            if (res.status === 200) {
                //TODO get user info from api, but maybe not
                //setCurrentUser({ name, nickname, email })
                dispatch(loaded({ method: 'validateToken', data: res.data }))
                clearCurrentUser()
                window.location.href = process.env.REACT_APP_TEST_URL

                return Promise.resolve()
            } else {
                return Promise.reject()
            }
        })
        .catch(e => {
            window.location.href = process.env.REACT_APP_TEST_URL

            dispatch(error({ method: 'validateToken', errorStatus: e.response.status }))
            return Promise.reject()
        })
}

export const selectTokenStatus = state => state.token.value
export const selectTokenErrorStatus = state => state.tokenValidation.errorStatus

const { loaded, loading, error } = slice.actions
export default slice.reducer